import React from 'react';
import { makeStyles } from '@material-ui/core/styles'
import { List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { Grid, Typography } from '@mui/material'

// Define custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '100vh',
    width: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  blog: { // coded by ChatGPT - lol looks great
    textDecoration: 'none',
    color: '#2196f3', // Blue color
    fontSize: '70px',
    fontWeight: 'bold',
    transition: 'color 0.3s ease', // Smooth color transition on hover
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)', // Text shadow for depth
    '&:hover': {
      color: '#ff5722', // Orange color on hover
    },
    width: '100%',
    marginLeft: '10em'
  },
  items: {
    display: 'inline-block',
    padding: '10px 20px',
    borderRadius: '5px',
    backgroundColor: '#2196f3', // Retro button color
    color: '#000', // Text color
    textDecoration: 'none', // Remove underline
    fontWeight: 'bold',
    textTransform: 'lowercase',
    fontFamily: 'Arial, sans-serif', // Choose a retro font if available
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', // Add a slight shadow
    transition: 'background-color 0.3s ease', // Smooth transition on hover
    '&::after': {
      content: '"AVAILABLE NOW"',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      width: '100%',
      background: 'linear-gradient(45deg, transparent 3%, #00e6f6 3%, #00e6f6 5%, #ff013c 5%)',
      textShadow: '-3px -3px 0 #f8f005, 3px 3px 0 #00e6f6',
      clipPath: 'inset(50% 50% 50% 50%)',
    },
    '&:hover::after': {
      animation: '$glitch 1s steps(2, end)',
    },
  },
  '@keyframes glitch': {
    '0%': {
      clipPath: 'inset(80% -6px 0 0)',
      transform: 'translate(-20px, -10px)',
    },
    '10%': {
      clipPath: 'inset(10% -6px 85% 0)',
      transform: 'translate(10px, 10px)',
    },
    '20%': {
      clipPath: 'inset(80% -6px 0 0)',
      transform: 'translate(-10px, 10px)',
    },
    '30%': {
      clipPath: 'inset(10% -6px 85% 0)',
      transform: 'translate(0px, 5px)',
    },
    '40%': {
      clipPath: 'inset(50% -6px 30% 0)',
      transform: 'translate(-5px, 0px)',
    },
    '50%': {
      clipPath: 'inset(10% -6px 85% 0)',
      transform: 'translate(5px, 0px)',
    },
    '60%': {
      clipPath: 'inset(40% -6px 43% 0)',
      transform: 'translate(5px, 10px)',
    },
    '70%': {
      clipPath: 'inset(50% -6px 30% 0)',
      transform: 'translate(-10px, 10px)',
    },
    '80%': {
      clipPath: 'inset(80% -6px 5% 0)',
      transform: 'translate(20px, -10px)',
    },
    '90%': {
      clipPath: 'inset(80% -6px 0 0)',
      transform: 'translate(-10px, 0px)',
    },
    '100%': {
      clipPath: 'inset(80% -6px 0 0)',
      transform: 'translate(0)',
    },
  }
}));

const BlogMenu = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={12} className={classes.container} >
      <Grid item xs={12}>
        <Typography className={classes.items} fontSize='2em' >
          Quantum Mechanics and the world around me
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.items} fontSize='2em' >
          How to get better at dribbling a basketball
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.items} fontSize='2em' >
          What to do when everything is boring
        </Typography>
      </Grid>
    </Grid>
  );
}

export default BlogMenu;
