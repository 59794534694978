import React from 'react'

import Home from './Pages/Home'
import AboutMe from './Pages/AboutMe'
import Blog from './Pages/Blog'
import Projects from './components/Projects'
import ContactMe from './components/ContactMe'

import { useMediaQuery } from '@material-ui/core'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'

import AnimatedCursor from 'react-animated-cursor'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// In the package.json, the start script is edited to make this run...
// in Ubuntu/Firefox. Can be changed for a more normal environment.
// --openssl-legacy-provider - put in front of run

function App() {
const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          type: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode]
  )

  return (
    <>
      <ThemeProvider theme={theme}>
        <AnimatedCursor
          innerSize={8}
          outerSize={8}
          color='55, 255, 20'
          outerAlpha={0.2}
          innerScale={0.7}
          outerScale={5}
          trailingSpeed={6}
        />
       <Router>
         <Switch>
           <Route exact path="/">
             <Home />
           </Route>
           <Route path="/about">
             <AboutMe />
           </Route>
           <Route path="/blog">
             <Blog />
           </Route>
           <Route path="/projects">
             <Projects />
            </Route>
           <Route path="/contact">
             <ContactMe />
           </Route>
         </Switch>
       </Router>
      </ThemeProvider>
    </>
  )
}

export default App
