import React from 'react'
import { Grid, Typography, useMediaQuery, makeStyles, useTheme, Card } from '@material-ui/core'

import chatty from '../assets/ichatty2.png'
import iphone from '../assets/iphone.png'
import macbook from '../assets/macbook.png'
import weathypic from '../assets/weathypic.png'
import brotein from '../assets/brotein.png'

import { Link } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
  container: {
    height: '100em',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1em',
    [theme.breakpoints.down('sm')]: {
      height: '75em',
      marginLeft: '0em',
      // This is so weird, seems like it centers it in this situation
    },
    [theme.breakpoints.down('xs')]: {
      width: '25em',
      height: '160em',
      // marginLeft: '0em',
    },
    [theme.breakpoints.down('md')]: {
      height: '120em',
    },
    width: '100%',
    backgroundColor: '#1f1f24',
  },
  title: {
    color: 'white',
    backgroundColor: '#1f1f24',
    padding: '1.5em',
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4.1em',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-.25em',
    },
  },
  image: {
    height: '30em',
    marginLeft: '0em',
  },
  blog: { // coded by ChatGPT - lol looks great
    textDecoration: 'none',
    color: '#2196f3', // Blue color
    fontSize: '45px',
    fontWeight: 'bold',
    transition: 'color 0.3s ease', // Smooth color transition on hover
    textShadow: '1px 1px 2px rgba(0,0,0,0.5)', // Text shadow for depth
    '&:hover': {
      color: '#ff5722', // Orange color on hover
    },
  },
  blogtainer: {
    height: '25em'
  }
}))

export default function Projects() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
  const matchesXL = useMediaQuery(theme.breakpoints.down('xl'))

  return (
    <>
      <Typography id='projects' variant='h4' align='center' className={classes.title}>
        Projects
      </Typography>

      <Grid container direction='column' className={classes.container} spacing={12}>
        <Grid item container alignItems='center' justifyContent='center' style={{ width: matchesXS ? '22em' : '35em' }}>
          <Grid item>
            <img
              src={macbook}
              alt='iphone'
              style={{
                width: matchesXS ? '21em' : '34.7em',
                position: 'absolute',
                marginLeft: matchesXS ? '-13em' : '0em',
                marginTop: matchesXS ? '-9.20em' : '-1.6em',
              }}
            />
            <img
              src={weathypic}
              alt='ios app'
              style={{
                width: matchesXS ? '16.1em' : '26.5em',
                marginLeft: matchesXS ? '-10.4em' : '4em',
                marginTop: matchesXS ? '-12em' : '0em',
              }}
            />
          </Grid>
          <Grid
            item
            direction='column'
            style={{ marginRight: matchesXS ? '7em' : '3em', width: '20em', marginTop: '4em', marginBottom: '5em' }}
          >
            <Typography gutterBottom variant='h4' style={{ color: 'white' }}>
              Weathy, my very first app!
            </Typography>
            <Typography gutterBottom variant='h6' style={{ color: 'white' }}>
              A single page app using React to quickly find out the weather, along with a 3 day forecast.
            </Typography>
            <Typography variant='h6' style={{ color: 'white', marginTop: '1em' }}>
              React, Material-UI
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems='center' justifyContent='center' style={{ width: '35em', marginBottom: '5em' }}>
          <Grid item>
            <img src={iphone} alt='iphone' style={{ width: '16em', position: 'absolute', marginLeft: '-1em', marginTop: '-1em' }} />
            <img src={chatty} alt='ios app' className={classes.image} />
          </Grid>
          <Grid item direction='column' style={{ marginLeft: '3em', width: '10em' }}>
            <Typography gutterBottom variant='h4' style={{ color: 'white' }}>
              Chatty
            </Typography>
            <Typography gutterBottom variant='h6' style={{ color: 'white', width: matchesSM ? '8em' : '10em' }}>
              A real-time message application using Socket.io.
            </Typography>
            <Typography variant='h6' style={{ color: 'white', width: matchesSM ? '8em' : '10em' }}>
              React Native, MongoDB, Socket.io
            </Typography>
          </Grid>
        </Grid>

        <Grid item container alignItems='center' justifyContent='center' style={{ width: '35em' }}>
          <Grid item>
            <img src={iphone} alt='iphone' style={{ width: '16em', position: 'absolute', marginLeft: '-1em', marginTop: '-1em' }} />
            <img src={brotein} alt='ios app' className={classes.image} />
          </Grid>
          <Grid item direction='column' style={{ marginLeft: '3em', width: '10em' }}>
            <Typography gutterBottom variant='h4' style={{ color: 'white' }}>
              Brotein
            </Typography>
            <Typography gutterBottom variant='h6' style={{ color: 'white', width: matchesSM ? '7em' : '10em' }}>
              My own vision of a fitness app focusing on simplicity.
            </Typography>
            <Typography variant='h6' style={{ color: 'white' }}>
              React Native
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction='column' alignItems='center' justifyContent='top' className={classes.blogtainer}>     
          <Grid item >
            <Link to='blog' className={classes.blog}>
              a blog
            </Link>
          </Grid>
        </Grid>
    </>
  )
}