import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useMediaQuery } from '@material-ui/core'
import { Grid, Typography } from '@mui/material'

import GlitchCanvas from 'glitch-canvas'
import image0 from '../images/tryThisOne.JPEG'
import glitch from '../images/glitchy.png'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: '20vh',
    width: '5em',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'gold',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '50%',
    marginBottom: '10em'
  },
  image: {
    backgroundImage: `url(${glitch})`,
    height: '70em',
    maxWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto'
  }
}))

// that damn xs prop that was there was causing all kinds of problems!!!

export default function ComponentTemplate() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))

  return (
    <>
    <Grid container direction='column' className={classes.image}>
      <Grid item>
        
      </Grid>
    </Grid>

    <Grid container direction='column' spacing={0} className={classes.container}>
      <Grid item> 
        <Typography variant="h2">
          My name is Richard James Channell Jr
        </Typography>
      </Grid>
    </Grid>
    <Grid container spacing={0} className={classes.text}>
      <Grid item>
       <Typography variant="h5" align="center" title='ChatGPT writes so much better than I!'>
         Howdy! I'm Richard Channell Jr., a proud native of Canton, Ohio with an insatiable passion for various facets of life. My  interests     span a diverse spectrum, and I find immense joy in exploring the worlds of sports, technology, and continuous learning. Currently, my     focus lies in the dynamic realms of programming, networking, and music production, where I channel my  creativity and analytical     skills into crafting digital experiences. Beyond these endeavors, I harbor ambitious aspirations  for the future. I aim to delve into     the fascinating realm of quantum mechanics, immersing myself in research that pushes the  boundaries of our understanding.     Additionally, I aspire to contribute to the realm of cybersecurity, envisioning a role  within the American Department of Defense     where I can employ my skills to safeguard digital landscapes. Join me on this  exciting journey as I navigate the intersection of       curiosity, innovation, and a relentless pursuit of knowledge.
       </Typography>
      </Grid>
    </Grid>
    </>
  )
}
