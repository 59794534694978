import React from 'react';

import HeroBlock2 from '../components/HeroBlock2';

function Home() {
  return (
    <>
      <HeroBlock2 />
    </>
  );
}

export default Home;  