import React from 'react'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Fade, useMediaQuery } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import Anime from 'react-anime'
import { Link } from 'react-router-dom'

import { Typography, Container, Grid } from '@mui/material'
// some new shit right here, it's been a while!

import moose from '../assets/moose.jpg'

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    marginTop: '0em',
    justifyContent: 'center',
    width: '100vw',
    marginLeft: '17em',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10em',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '.5em',
    },
  },
  button: {
    // position: 'absolute',
    zIndex: 1, // omg I just thought this up, I'm awesome
    color: 'white',
    fontWeight: '800',
    marginTop: '1em',
    marginLeft: '9em'
  },
  heroContainer: {
    backgroundImage: `url(${moose})`,
    backgroundSize: 'cover',  /* Adjust size to maintain aspect ratio and cover the entire container */
    backgroundPosition: 'center',  /* Center the image in the container */
    backgroundRepeat: 'no-repeat',  /* Prevent the image from repeating */
    height: '110vh'
  }
}))

export default function HeroBlock2() {
  const classes = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const matchesXS = useMediaQuery(theme.breakpoints.down('xs'))
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'))
  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'))
  
  return (
    <Container maxWidth={false} className={classes.heroContainer} >
     <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} md={true}>
        <Typography variant="h2" gutterBottom style={{ fontFamily: 'Georgia', fontWeight: 'bold', marginTop: '5.5em', color: 'gold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }} >
          richie.works
        </Typography>
        <Grid item container direction='column' spacing={6} className={classes.buttonContainer}>
            <Grid item>
              <Anime duration={1000} opacity={[0, 1]} delay={2700} easing={'easeOutCubic'}>
                <Button size='large' color='primary' variant='text' className={classes.button}>
                  <Link to='about' style={{ textDecoration: 'none', color: 'inherit', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                    About Me
                  </Link>
                </Button>
              </Anime>
            </Grid>
            <Grid item>
              <Anime duration={1000} opacity={[0, 1]} delay={2900} easing={'easeOutCubic'}>
                <Button size='large' color='primary' variant='text' className={classes.button}>
                  <Link to='projects' style={{ textDecoration: 'none', color: 'inherit', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                    Projects
                  </Link>
                </Button>
              </Anime>
            </Grid>
            <Grid item>
              <Anime duration={1000} opacity={[0, 1]} delay={3100} easing={'easeOutCubic'}>
                <Button size='large' color='primary' variant='text' className={classes.button}>
                  <Link to='contact' style={{ textDecoration: 'none', color: 'inherit', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                    Contact
                  </Link>
                </Button>
              </Anime>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
    </Container>
  )
}

//  <Grid item xs={12} md={6}>
        //    <img src={moose} alt="Descriptive alt text" style={{ width: '100%', borderRadius: '10px' }}/>
       // </Grid>