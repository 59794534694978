import React, { useState } from 'react'
import emailjs from 'emailjs-com'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '40em',
    backgroundColor: '#1f1f24',
  },
  text: {
    color: 'white',
    width: '20em',
  },
}))

export default function ContactMe() {
  const classes = useStyles()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  const handleSubmit = (event) => {
    event.preventDefault()
    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: 'Richard',
      // subject,
      message_html: message,
    }
    if (message.length > 10 && name.length > 2 && email.length > 5) {
      emailjs.send('service_l6jqbms', 'template_ikn38fi', templateParams, 'user_oXNe6EOZYF7FtOrv6hw4R')
      alert('Email sent!')
    } else {
      alert('Please fill out the form completely!')
    }
  }

  const handleChangeName = (event) => {
    setName(event.target.value)
    console.log(name)
  }
  const handleChangeEmail = (event) => {
    setEmail(event.target.value)
    console.log(email)
  }
  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
    console.log(message)
  }

  return (
    <Grid container className={classes.container}>
      <Grid item container align='center' direction='column'>
        <Grid item>
          <Typography variant='h3' style={{ color: 'white', marginTop: '2em', fontFamily: 'Andale Mono' }} id='contact'>
            Contact Me
          </Typography>
        </Grid>
        <Grid item style={{ marginTop: '1em' }}>
          <TextField value={name} onChange={handleChangeName} InputProps={{ className: classes.text }} label='Name' />
        </Grid>
        <Grid item>
          <TextField value={email} onChange={handleChangeEmail} className={classes.text} color='primary' label='Email' />
        </Grid>
        <Grid item style={{ marginTop: '2em' }}>
          <TextField
            value={message}
            onChange={handleChangeMessage}
            className={classes.text}
            multiline
            rows={5}
            color='primary'
            label='Message'
          />
        </Grid>
        <Button type='submit' justify='center' align='center' onClick={handleSubmit} style={{ marginTop: '2em' }}>
          Send Email
        </Button>
      </Grid>
    </Grid>
  )
}